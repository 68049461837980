<template>
  <div class="new-project-page">
    <div class="button next-tab"
         v-if="currentTabNumber != $store.getters.tabs.length - 1 && $store.getters.tabs[currentTabNumber + 1].active"
         @click="goNext">
      <div class="chevron-icon"></div>
    </div>
    <div class="content">
      <div class="new-project-progress">
        <div class="progress-tab" v-for="(tab, key) in $store.getters.tabs" :key="tab.name">
          <div class="progress-name" :class="{'done': tab.done && tab.active,
          'active': $store.getters.activeTab === tab, 'inactive': !tab.active}"
               v-if="tab.active == true" @click="makeActive(tab)">{{ key + 1 }}
            <span>{{ tab.name }}</span>
          </div>
          <div class="progress-arrow" v-if="key != $store.getters.tabs.length - 1"></div>
        </div>
      </div>
      <div class="project-page-content">
        <component v-if="$store.getters.project" :is="$store.getters.activeTab.widget"></component>
        <!--        <ProjectOverview v-show="activeTab == 1"></ProjectOverview>
                <ProjectFloors v-show="activeTab == 2"></ProjectFloors>
                <ProjectUnits v-if="activeTab == 3"></ProjectUnits>
                <ProjectMap v-show="activeTab == 4"></ProjectMap>
                <ProjectGalleries v-show="activeTab == 5"></ProjectGalleries>
                <ProjectPublish v-show="activeTab == 6"></ProjectPublish>-->
      </div>
      <!--    <div class="project-page-controls" v-if="!loaded">
            <div class="project-page-button" :class="{invisible: activeTab == 1}" @click="goBack()">Back</div>
            <div class="project-page-warnings" v-show="activeTab == 6">
              <div class="warning-item red" v-for="warning in publishWarnings">{{warning.text}}</div>
            </div>
            <div class="project-page-subcontrols">
              <div class="project-page-button" @click="publish" v-if="this.project.published == true">Unpublish</div>
              <div class="project-page-button blue-button" v-if="checkNext()" @click="goNext">Next</div>
              <div class="project-page-button inactive-button" v-if="!checkNext() && activeTab !== 6">Next</div>
              <div class="project-page-button blue-button" @click="publish" v-if="this.publishWarnings.length === 0 && activeTab == 6 && this.project.published != true">Publish</div>
              <div class="project-page-button inactive-button" v-if="this.publishWarnings.length > 0 && activeTab == 6 && this.project.published != true">Publish</div>
            </div>
          </div>-->
      <div class="project-page-publish" v-if="loaded && !published">
        <div class="publish-button">
          Loading
        </div>
      </div>
    </div>
    <div class="button prev-tab" v-if="currentTabNumber != 0"
         @click="goBack">
      <div class="chevron-icon"></div>
    </div>
  </div>
</template>

<script>
import ProjectOverview from '@/components/edit/ProjectOverview.vue'
import ProjectFloors from '@/components/edit/ProjectFloors.vue'
import ProjectUnits from '@/components/edit/ProjectUnits.vue'
import ProjectMap from '@/components/edit/ProjectMap.vue'
import ProjectGalleries from '@/components/edit/ProjectGalleries.vue'
import ProjectPublish from '@/components/edit/ProjectPublish.vue'
import constants from "../Constants";

export default {
  name: 'editproject',
  components: {
    ProjectOverview, ProjectFloors, ProjectUnits, ProjectMap, ProjectGalleries, ProjectPublish
  },
  data: () => ({
    publishWarnings: [],
    tabsCount: 1,
    published: false,
    loaded: false,
    logoPreview: '',
    personalLink: '',
    units: [],
    oldProject: {}
  }),
  computed: {
    currentTabNumber() {
      return this.$store.getters.tabs.indexOf(this.$store.getters.activeTab);
    },
    checkName() {
      return this.$store.getters.project.name.length > 0;
    }
  },
  mounted() {
    this.$store.dispatch('TABS_CHANGE', this.$store.getters.tabs[0]);
  },
  methods: {
    deleteMarker(e) {
      let obj = this;
      let marker_id = e.target.getAttribute('marker-id');
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      let project_id = window.location.href.split('project_id=');
      project_id = project_id[project_id.length - 1];
      let data = {
        user_id: user.uid,
        token: token,
        project_id: project_id,
        marker_id: marker_id,
      };

      $.ajax({
        url: constants.BACKEND_URL + 'marker/delete-marker',
        type: 'POST', // важно!
        data: data,
        cache: false,
        dataType: 'json',
        success: function (respond, status, jqXHR) {
          if (respond.ok === 1) {
            for (var i = 0; i < obj.project.markers.user_markers.length; i++) {
              if (obj.project.markers.user_markers[i].id == marker_id) {
                obj.project.markers.user_markers[i].setMap(null);
              }
            }
          }
        },
        // функция ошибки ответа сервера
        error: function (jqXHR, status, errorThrown) {
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    goNext() {
      if (this.currentTabNumber != this.$store.getters.tabs.length - 1) {
        this.saveOverview();
        if (this.$store.getters.tabs[this.currentTabNumber].active === true) {
          this.makeActive(this.$store.getters.tabs[this.currentTabNumber + 1]);
        }
      }
    },
    saveDraft() {
      if (this.$store.getters.activeTab == this.$store.getters.tabs[0]) {
        this.saveOverview();
      }
    },
    saveOverview() {
      this.$store.dispatch('PROJECT_SAVE');
    },
    goBack() {
      if (this.currentTabNumber != 0) {
        if (this.$store.getters.tabs[this.currentTabNumber - 1].active === true) {
          this.makeActive(this.$store.getters.tabs[this.currentTabNumber - 1]);
        }
      }
    },
    makeActive(e) {
      if(this.$store.getters.activeTab.active === false) {
        return;
      }
      this.saveDraft();
      this.$store.dispatch('TABS_CHANGE', e);
      this.$store.dispatch('PROJECT_TAKE');
    },
    publish() {
      let data = new FormData();
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      data.append('user_id', user.uid);
      data.append('token', token);
      data.append('project_id', this.$store.getters.project.id);
      let obj = this;
      $.ajax({
        url: constants.BACKEND_URL + 'project/publish',
        type: 'POST', // важно!
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
          obj.stopSave = false;
          if (respond.ok === 1) {
            this.$store.getters.project.published = respond.published;
            if (this.$store.getters.project.published == 1) {
              let protocol = document.location.protocol;
              let host = document.location.host;
              obj.personalLink = protocol + '//' + host + '/show/' + respond.personal_link;
              this.$store.getters.project.published = true;
            } else {
              this.$store.getters.project.published = false;
              obj.personalLink = '';
            }
          } else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        error: function (jqXHR, status, errorThrown) {
          obj.stopSave = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    checkNext() {
      if (this.activeTab === 1) {
        if (this.$store.getters.project && this.$store.getters.project.name.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (this.activeTab === 2) {
        if (this.$store.getters.project.floors.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (this.activeTab === 3) {
        let flag = false;
        if (this.$store.getters.project.house_type == 1) {
          for (let i = 0; i < this.$store.getters.project.floors.length; i++) {
            if (this.$store.getters.project.floors[i].units.length > 0) {
              flag = true;
            }
          }
        } else {
          if (this.$store.getters.project.lot_info != null) {
            flag = true;
          }
        }

        return flag;
      }
      if (this.activeTab === 4) {
        if (!this.$store.getters.project.mapActivate || (this.$store.getters.project.mapActivate === true
          && this.$store.getters.project.map.address !== '')) {
          return true;
        } else {
          return false;
        }
      }
      if (this.activeTab === 5) {
        if (this.progresses[4].active === true && (this.$store.getters.project.galleries.length > 0)) {
          return true;
        } else {
          return false;
        }
      }
      if (this.activeTab === 6) {
        return false;
      }
    },
    createInfoWindowDom(info) {
      var container = document.createElement('div');
      container.classList.add('custom-marker');
      var name = document.createElement('div');
      name.classList.add('map-info-name');
      name.textContent = info.name;
      var address = document.createElement('div');
      address.classList.add('map-info-address');
      address.textContent = info.address;
      var description = document.createElement('div');
      description.classList.add('map-info-description');
      description.textContent = info.description;
      container.appendChild(name);
      container.appendChild(address);
      container.appendChild(description);
      if (info.creator == 0) {
        var button = document.createElement('div');
        button.classList.add('map-info-button');
        button.textContent = 'Delete marker';
        button.setAttribute('marker-id', info.id);
        button.addEventListener('click', this.deleteMarker);
        container.appendChild(button);
      }
      return container;
    },
    createMarker(marker) {
      var icon = '';
      switch (Number.parseInt(marker.type)) {
        case 1:
          icon = constants.BACKEND_URL + '/img/Cultural.png';
          break;
        case 2:
          icon = constants.BACKEND_URL + '/img/Restaurants.png';
          break;
        case 3:
          icon = constants.BACKEND_URL + '/img/Sports.png';
          break;
        case 4:
          icon = constants.BACKEND_URL + '/img/Nature.png';
          break;
      }
      var userMarker = new google.maps.Marker({
        position: {lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)},
        map: map,
        icon: icon,
      });
      if (marker.name == null) {
        marker.name = '';
      }
      if (marker.address == null) {
        marker.address = '';
      }
      if (marker.description == null) {
        marker.description = '';
      }
      window.infowindow = new google.maps.InfoWindow();
      userMarker.addListener("click", () => {
        window.infowindow.setContent(this.createInfoWindowDom(marker));
        window.infowindow.open(map, userMarker);
      });
      userMarker.id = marker.id;
      return userMarker;
    },
  },
}
</script>
<style scoped lang="less">
.content {
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
}

.chevron-icon {
  width: 11px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='39' viewBox='0 0 14 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4 2.40625L2.00002 19.4609L12.4 36.5156' stroke='white' stroke-opacity='0.8' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.next-tab .chevron-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='39' viewBox='0 0 15 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2.40625L12.4 19.4609L2 36.5156' stroke='white' stroke-opacity='0.8' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.button.prev-tab {
  left: 0;
}
.button.next-tab {
  right: 0;
}

.button:hover {
  background: rgba(44, 121, 211, 0.7);
}

.button {
  transition: all .3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  top: 0;
  width: 80px;
  height: 100vh;
  position: fixed;
  background-color: rgba(111, 112, 114, 0.4);
}

.new-project-page {
  min-height: calc(100vh - 64px);
  padding-top: 40px;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: -3%;
  margin-right: -3%;
  display: flex;
  justify-content: space-between;

  & .project-page-content {
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 80px;
  }

  & .new-project-progress {
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;

    & .progress-tab:first-child {
      padding-left: 0;
    }

    & .progress-tab:last-child {
      width: auto;
      padding-right: 0;
    }

    & .progress-tab {
      width: 100%;
      display: flex;
      align-items: center;

      & .progress-name {
        font-family: RobotoRegular, sans-serif;
        font-size: 20px;
        color: #BDBDBD;
        padding-right: 10px;
        padding-left: 10px;
        white-space: nowrap;
        cursor: pointer;
      }

      .done {
        color: #AEAFAF;
      }

      &:first-child .progress-name {
        padding-left: 0;
      }

      &:last-child .progress-name {
        padding-right: 0;
      }

      & .progress-arrow {
        width: 100%;
        height: 1px;
        background-color: #BDBDBD;
      }
    }

    & .active.progress-name {
      color: #2C79D3;
      font-family: RobotoBold, sans-serif;
      cursor: default;
    }

    & .progress-name.inactive {
      opacity: 0.3;
      cursor: default;
    }
  }

  & .project-page-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 1px solid #828282;
    padding-bottom: 30px;
    padding-left: 3%;
    padding-right: 3%;

    & .project-page-subcontrols {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  & .project-page-publish {
    color: #2F80ED;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-left: 3%;
    padding-right: 3%;
    border-top: 1px solid #828282;

    & .publish-icon {
      display: inline-block;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2222%22%20height%3D%2216%22%20viewBox%3D%220%200%2022%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M20.1209%200.00116978C19.7345%200.0134322%2019.3682%200.175795%2019.0995%200.453796C15.4129%204.14845%2011.4666%208.38197%207.78334%2012.1182L2.52565%207.78902C2.37852%207.64484%202.20301%207.53282%202.01027%207.4601C1.81753%207.38738%201.61178%207.35554%201.40607%207.36659C1.20037%207.37764%200.999205%207.43134%200.815366%207.5243C0.631526%207.61725%200.469025%207.7474%200.338182%207.90651C0.207339%208.06563%200.111013%208.25021%200.0553227%208.44854C-0.000367885%208.64688%20-0.0142066%208.85462%200.0146855%209.05858C0.0435777%209.26255%200.114569%209.45828%200.223152%209.63335C0.331734%209.80841%200.475535%209.95898%200.645421%2010.0755L6.95929%2015.2752C7.244%2015.5107%207.60627%2015.6317%207.97538%2015.6145C8.34449%2015.5973%208.69396%2015.4432%208.95558%2015.1823C12.9476%2011.1817%2017.2807%206.49431%2021.2119%202.55457C21.4285%202.34542%2021.5764%202.0754%2021.6361%201.78028C21.6958%201.48516%2021.6644%201.17888%2021.5461%200.902001C21.4278%200.625121%2021.2282%200.390722%2020.9737%200.229852C20.7192%200.0689815%2020.4218%20-0.0107745%2020.1209%200.00116978Z%22%20fill%3D%22%232F80ED%22/%3E%0A%3C/svg%3E%0A');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      background-position: center;
      background-size: contain;
    }

    & .publish-button {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}
</style>
